import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ProfileForm from "../components/form-components/ProfileForm"
import { MDBContainer } from 'mdb-react-ui-kit';
import tovabb from "../assets/media/icons/tovabb nyil.svg"
import {OpenStreetMap} from "../components/openstreetmap/OpenStreetMap";

const Kapcsolatpage = () => {

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => window.scrollTo({
      top: 0,
      behavior: "smooth"
    }), 100);
  }, []);


  return (
    <>
    <section className="kapcsolat-map-section kapcsolat-sect" id ="kapcsolat-map-section">
      <MDBContainer style={{maxWidth: "1008px"}} className='p-0'>
        <div className="kapcsolat-map-div">
          <div className="left-col-div">
            <div className="navigate-div" style={{marginTop: 0}}>
              <button onClick={() => { navigate('/home')}}>Főoldal</button>
              <img src={tovabb} alt="tovabb"/>
              <button onClick={() => navigate('/kapcsolat')}>Info</button>
            </div>
            <div className="up-div">
              <h3>Kapcsolat</h3>
              <span>A Vértes Agorája</span>
              <span>Cím: Tatabánya, Szent Borbála tér 1.</span>
              <span>Telefon: +36 34 688 100</span>
              <span>E-mail: <a href={"mailto:info@avertesagoraja.hu"}>info@avertesagoraja.hu</a>, <a href={"mailto:jegypenztar@avertesagoraja.hu"}>jegypenztar@avertesagoraja.hu</a></span>
              <span>Honlap: <a href="www.avertesagoraja.hu" target="_blank"  rel="noopener noreferrer">www.avertesagoraja.hu</a></span>
            </div>
            <div className="down-div">
              <p>Nyitvatartás:</p>
              <span>Hétfőtől szombatig 10:00-20:00</span>
              <span>Vasárnap ZÁRVA</span>
              <p>A jegypénztár nyitvatartása:</p>
              <span>Hétfőtől szombatig 10:00-19:00</span>
              <span>Vasárnap ZÁRVA</span>
              <span className="pt-2">Ebédszünet: 13:00-13:45</span>
            </div>
          </div>
          <div className="right-col-div">
            <div className="map-keret" id="map-keret">
                <OpenStreetMap lat={47.581010} lng={18.399630} />
            </div>
          </div>
        </div>
      </MDBContainer>
    </section>
    <section className="urlap-section" id="urlap-section">
      <MDBContainer style={{maxWidth: "1008px"}} className='p-0'>
        <div className="kapcsolat-title">
          <span>Kérdése van? Miben segíthetünk?</span>
        </div>
        <ProfileForm />
      </MDBContainer>
    </section>
    </>
  );
};

export default Kapcsolatpage;
