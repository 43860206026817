import React, { useEffect, useState } from "react";
import {useNavigate, useParams} from "react-router-dom";
import 'react-calendar/dist/Calendar.css';
import moment from 'moment'
import ProgramCard from "../components/cards/ProgramCard"
import tovabb from "../assets/media/icons/tovabb nyil.svg"

import { MDBContainer, 
         MDBCol, 
         MDBRow
          } from 'mdb-react-ui-kit';
import {useSelector} from "react-redux";

const ProgramMusterPage = () => {


  moment.updateLocale('hu', {
    months : [
        "Január", "Február", "Március", "Április", "Május", "Június", "Július",
        "Augusztus", "Szeptember", "Október", "November", "December"
    ]
  });
  const { typeId } = useParams();
  const navigate = useNavigate();
  const [direction, setDirection] = useState(window.outerWidth);
  const { eventTypes, guestEvents, programCategoryId } = useSelector(state => state);
  const eventpageName = eventTypes.filter(k => k.id === programCategoryId)[0]?.name || ``;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [typeId]);

  const handleWindowSizeChange = () => {
    setDirection(window.visualViewport.width);
  };

  useEffect(() => {
    window.visualViewport.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.visualViewport.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <>
    <section className="kovetkezo-programok-section events-page" id ="kovetkezo-programo-section">
      <MDBContainer style={{maxWidth: "1008px"}} className='p-0'>
        <div className="navigate-div" style={{marginTop: 0}}>
          <button onClick={() => { navigate('/home')}}>Főoldal</button>
          <img src={tovabb} alt="tovabb"/>
          <button>{eventpageName}</button>
        </div>
        <div>
          <div className="program-title">
            <span>{eventpageName}</span>
          </div>
          <MDBRow className="m-0 programok-row">
            {guestEvents.map((k,n) => {return (
              <MDBCol md="4" className="programok-col px-0" key={n}>
                <ProgramCard msg={k} allandoprogramok={false} kovetkezoprogramok={true} programId={programCategoryId} categoryName={null} linkText={null}/>
              </MDBCol>
            )})}
        
          </MDBRow>
        </div>
      </MDBContainer>
    </section>
    </>
  );
};

export default ProgramMusterPage;
