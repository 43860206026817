import React, { useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import { MDBRow, MDBCol, MDBTable, MDBTableBody } from 'mdb-react-ui-kit';
import {BlurhashImage} from "../components/blurhash/BlurhashImage";
import {getReplaceText} from "../util/get-replace-text";
import {useSelector} from "react-redux";
import CanvasFlipBook from "react-pageflip";
import {pdfjs} from "react-pdf";

const TeremberlesPage = (props) => {
    const navigate = useNavigate();

    const pathname = window.location.pathname.split("/")[window.location.pathname.split("/").length - 1];
    const [infoId, setInId] = useState(parseInt(window.location.pathname.split("/")[window.location.pathname.split("/").length - 1]));
    const {infopages} = useSelector(state => state);

    const [pdfPages, setPDFPages] = useState([]);
    const [ showPdf, setShowPdf ] = useState(false);
    const [catalogLoaded, setCatalogLoaded] = useState(false);

    const getPDF = async (url) => {
        //
        // Asynchronous download PDF
        //
        const loadingTask = pdfjs.getDocument(url);
        return await loadingTask.promise;
    }

    const renderPage = async (pdf, pageNumber) => {

        //
        // Fetch the first page
        //
        const page = await pdf.getPage(pageNumber + 1);
        const scale = 2.0;
        const viewport = page.getViewport({ scale });
        // Support HiDPI-screens.
        const outputScale = window.devicePixelRatio || 1;

        //
        // Prepare canvas using PDF page dimensions
        //
        const canvas = document.getElementById(`pdf-canvas-page-${ pageNumber }`);
        if (!canvas) {
            return;
        }
        const context = canvas.getContext("2d");

        canvas.width = Math.floor(viewport.width * outputScale);
        canvas.height = Math.floor(viewport.height * outputScale);

        canvas.style.width = Math.floor(viewport.width) + "px";
        canvas.style.height = Math.floor(viewport.height) + "px";

        const transform = outputScale !== 1
            ? [outputScale, 0, 0, outputScale, 0, 0]
            : null;

        const renderContext = {
            canvasContext: context,
            transform,
            viewport,
        };
        page.render(renderContext);
        return canvas.toDataURL('image/jpeg');
    }

    const loadPDF = async (pdf) => {
        for (let i = 0; i < pdf.numPages; i++) {
            await renderPage(pdf, i);
        }
    }

    const loadCatalog = () => {
        if (catalogLoaded) {
            return;
        }
        setCatalogLoaded(true);
        getPDF('/katalogus.pdf').then(pdf => {
            const pages = [];
            for (let i = 0; i < pdf.numPages; i++) {
                pages.push(0);
            }
            setPDFPages(pages);
            setTimeout(() => {
                loadPDF(pdf);
            }, 200)
        });
    }

    const closePdf = e => {
        if (e.target.className === 'pdf-viewer-container') {
            setShowPdf(false);
        }
    }

    useEffect(() => {
        setInId(parseInt(pathname))
    }, [pathname]);

    let h = (window.innerHeight * 0.9);
    let w = h / (1190 / 839);

    if (window.innerWidth < window.innerHeight) {
        w = (window.innerWidth * 0.9) * 0.5;
        h = w * (1190 / 839);
    }


    return (

        <>
            {infopages.length > 0 && (<>
                <h3>{props.infopageById.title}</h3>
                {props.infopageById.image !== null && (
                    <img className="teremberles-first" src={props.infopageById.image} alt="teremberles_1"/>
                )}
                {props.infopageById.documents && props.infopageById.documents.length > 0 && (
                    <div className="documents px-0">

                        <MDBTable hover>
                            {props.infopageById.documents.map((k, n) => {
                                return (
                                    <MDBTableBody key={n}>
                                        <tr>
                                            {/*<th scope='row'>{n+1}</th>*/}
                                            <td>
                                                <div className="documents-download">
                                                    <a href={k.url} target="_blank" rel="noreferrer">
                                                        {k.name !== null ? k.name : "Dokumentum"}
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    </MDBTableBody>
                                )
                            })}
                        </MDBTable>
                    </div>
                )}
                <div className="description" dangerouslySetInnerHTML={{__html: props.infopageById.content}}></div>

                <div className="teremberles-grid">
                    {props.infopageById.children.map((k, n) => {
                        return (
                            <div className="teremberles-box" key={n}>
                                <>
                                    {infoId === infopages?.filter(k => k.title === "Rendezvényszervezés, terembérlés")[0].id && (
                                        <div className={
                                            k.title === "A Vértes Agorája" ? "terem-card-div agora" :
                                                k.title === "Kertvárosi Bányász Művelődési Otthon" ? "terem-card-div banyasz" :
                                                    k.title === "József Attila Művelődési Ház" ? "terem-card-div ja" :
                                                        k.title === "Puskin Művelődési Ház" ? "terem-card-div puskin" :
                                                            k.title === "Széchenyi István Művelődési Ház" ? "terem-card-div szi" : "terem-card-div katalogus"
                                        } onClick={() => {
                                            navigate('/infos/rendezvenyszervezes_teremberles/' + getReplaceText(k.title))
                                        }}>
                                            <div className="image-div">
                                                {k.image ? (
                                                    <BlurhashImage image={k.image} blurhash={k.imageBlurhash}/>
                                                ) : null}
                                            </div>
                                            <div className="title-div">
                                                <p>{k.title}</p>
                                            </div>
                                        </div>
                                    )}

                                    {(infoId === infopages?.filter(k => k.title === "Pályázatok")[0].id ||
                                        infoId === infopages?.filter(k => k.title === "Szervezetünk")[0].id ||
                                        infoId === infopages?.filter(k => k.title === "Gyerekeknek")[0].id) && (
                                        <div
                                            className={n % 2 === 0 ? "palyazatok-card-div right" : "palyazatok-card-div left"}
                                            onClick={() => {
                                                navigate('/infos/' + getReplaceText(infopages?.find(i => i.id === infoId)?.title) + '/' + getReplaceText(k.title))
                                            }}>
                                            <div className="title-div">
                                                <p>{k.title}</p>
                                            </div>
                                        </div>
                                    )}


                                </>

                            </div>
                        )
                    })}

                    {props.infopageById.relatedLinks && props.infopageById.relatedLinks.length > 0 && (
                        <>
                            {props.infopageById.relatedLinks.map((k, n) => {
                                return (
                                    <div className="loadmore-div px-0" key={n}>
                                        <button className="muster-button kiemelt"
                                                onClick={() => {
                                                    if (k.link.endsWith('.pdf')) {
                                                        loadCatalog();
                                                        setShowPdf(true);
                                                    } else {
                                                        window.open(k.link.replace(`www.`, "https://"), '_blank')
                                                    }
                                                }}>
                                            <span className="button-text">{k.title}</span></button>
                                    </div>
                                )
                            })}
                        </>
                    )}
                </div>
            </>)}
            <div className="pdf-viewer-container" onClick={closePdf}
                 style={{visibility: showPdf ? `visible` : `hidden`, pointerEvents: showPdf ? `all` : `none`}}>
                <div className="pdf-viewer">
                    <CanvasFlipBook width={w} height={h} startPage={0} showCover={true} usePortrait={false}>
                        {pdfPages.map((page, i) => <canvas id={`pdf-canvas-page-${i}`}/>)}
                    </CanvasFlipBook>
                </div>
            </div>
        </>
    );
};

export default TeremberlesPage;
