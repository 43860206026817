import Cookies from "universal-cookie";
import InterAxios from "../Service/InterAxios";
import {store} from "../redux/store";

const defaultOptions = {
    placeId: undefined,
    typeId: undefined,
    recurring: undefined
}

export const listGuestEvents = async (options = defaultOptions) => {

    const cookies = new Cookies();
    const interAxios = new InterAxios('App', () => cookies.get('authtoken')?.token);

    if (options.placeId === undefined) {
        options.placeId = store.getState().currentEventPlaceId;
    }

    return new Promise((resolve, reject) => {
        interAxios.get(
            `/events/guest?placeId=${ options.placeId }${ options.typeId ? `&typeId=${ options.typeId }` : `` }${ options.recurring !== undefined ? `&recurring=${ options.recurring ? 'yes' : `no` }` : `` }`,
            function (response) {
                resolve(response.data);
            },
            function (error) {
                reject(error.response?.data);
            });
    })

}