// react imports
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MDBContainer } from 'mdb-react-ui-kit';
import tovabb from "../assets/media/icons/tovabb nyil.svg"
import muster_image from "../assets/media/images/muster image.png"
import {useSelector} from "react-redux";

const NepmesepontPage = (props) => {

  const navigate = useNavigate();

  const [direction, setDirection] = useState(window.outerWidth);

  const { infopages } = useSelector((state) => state);

  useEffect(() => {
    setTimeout(() => window.scrollTo({
      top: 0,
      behavior: "smooth"
    }), 100);
  }, []);
  const handleWindowSizeChange = () => {
    setDirection(window.visualViewport.width);
  };

  useEffect(() => {
    window.visualViewport.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.visualViewport.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  return (
    <>
      
      <section className="nepmese-map-section" id ="kapcsolat-map-section" >
        <MDBContainer style={{maxWidth: "1038px"}} className='nepmese-container p-0'>
          <div className="kapcsolat-map-div">
            <div className="one-col-div pb-4">
              <div className="navigate-div">
                <div>
                  <button onClick={() => { navigate('/home')}}>Főoldal</button>
                  <img src={tovabb} alt="tovabb"/>
                </div>
                <div>
                  <button>Népmesepont</button>
                </div>
              </div>
              <div className="nepmesepont">
                <h3>Népmesepont</h3>
                <div className="title">Ez a nap más, mint a többi...</div>
                <div className="description">
                Mi is jól tudjuk! Ezért kínálunk új, mesés szolgáltatást: tematikus születésnapi zsúrokat. Lovagkaland, tündérmóka, virgonc és csudajó parti várja a gyerekeket Tatabányán, a NépmesePontban. </div>
                <div className="description">
                Az ünnepelt és a meghívottak a vidám, jelmezes játékok, a kincskeresés, a csapatversenyek vagy akár egy titkos kaland élményével gazdagodhatnak, s ajándékokkal térhetnek haza. 
                Nem utolsó sorban, a szülőknek sem kell aggódniuk, hogy az önfeledt játék egy szőnyegen hagyott, makacs tortanyomban végződik.</div>
                <div className="description">
                S hogy mit rejtenek az egyes programok?</div>
                <div className="description">
                A LOVAGKALAND során megelevenedik egy középkori lovagi udvar, ahol különböző - erőt és rátermettséget kívánó - próba teljesítésével szabadíthatják 
                ki a királylányt, vagy kereshetnek meg egy titkos kincset a résztvevő gyerekek.</div>
                <div className="description">
                A TÜNDÉRMÓKA varázsútra invitálja az ünneplők seregét, amelynek végén, a kalandos próbák után a Varázshegyen megtalálhatják a születésnapos tündérkirálylányt.</div>
                <div className="description">
                A VIRGONC szülinap a szuperaktív gyerekek ünnepe. Versenyek, erőfitogtatás: kötélhúzás, célba dobás, mocsárjárás, egyensúlyozás gondoskodik a kimerítően jó hangulatról.</div>
                <div className="description">
                A CSUDAJÓ PARTI azoknak való, akik szeretik a meglepetéseket. Váratlan kalandok, őrületes játékok, móka és kacagás garantálja az emlékezetes szülinapi élményt.</div>
                <img src={muster_image} alt="muster-kép"/>
                <div className="title">Információ a szülőknek</div>
                <div className="description">Az alapcsomag tartalma</div>
                <div className="description">A tematikus programot megvalósító animátor, meghívó, szörp, dekoráció, jelmez, fényképek a zsúrról</div>
                <div className="description">Plusz szolgáltatás: torta és/vagy pizza</div>
                <div className="description">Választható időpontok: szombat-vasárnap, 10:00-12:00 vagy 15:00-17:00 h között</div>
                <div className="description">Korosztály: 5-12 évesek Létszám | 8-15 fő</div>
                <div className="title">Információ a szülőknek</div>
                <div className="description">A zsúr előtt legkésőbb 5 nappal:</div>
                <div className="description">
                  <li>Készpénzben, a helyszínen.</li>
                  <li>Átutalással</li>
                  <li>Bankkártyával és SZÉP kártyával (A Vértes Agorája jegypénztárában). Ebben az esetben, kérjük, feltétlenül őrizze meg a fizetési bizonylatot! A kártyaelfogadásról bővebben <a href="https://avertesagoraja.hu/blog/2017/01/01/utalvany-es-kartyaelfogadas/">ITT</a> tájékozódhat.</li>
                </div>
                <div className="title">Lemondás</div>
                <div className="description">A foglalás lemondására legkésőbb három nappal a választott időpont előtt van lehetőség.</div>
                <div className="title">A foglalás menete</div>
                <div className="description">Kérjük, a választott időpont előtt minimum három héttel korábban jelentkezzenek!A foglalás a visszaigazolás után válik csak aktívvá. A visszaigazolást minden esetben két munkanapon belül megtesszük.</div>
                <div className="description">Jelentkezni lehet:Telefonon (munkanapokon, 8:00-16:00 h): +36 70 683-5018E-mailen: <a href={"mailto:szulinap@avertesagoraja.hu"}>szulinap@avertesagoraja.hu</a></div>
                {infopages?.filter(k => k.title === "Adatkezelési tájékoztatók").length > 0 && (
                <div className="description">Az adatvételmi tájékoztató 
                
                  <a  onClick={() => {
                                      navigate('/infosfooldal/' + infopages.filter(k => k.title === "Adatkezelési tájékoztatók")[0]?.id)}}> Itt </a>
                
                
                 érhető el.</div>)}
                <div className="loadmore-div">
                  <button  className="muster-button kiemelt" onClick={() => window.open("https://docs.google.com/forms/d/14B7IhmTIyqkNhOcWzS4PcZNIZ-eIa_gsidB1xdmaHqY/viewform?pli=1&pli=1&edit_requested=true", '_blank')}><span className="button-text">Online foglalás</span></button>
                </div>
              </div>
            </div>
          </div>
        </MDBContainer>
      </section>
    
    </>
  );
};

export default NepmesepontPage;
