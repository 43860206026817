import {getCurrentEventPlaceId, getEventPlace} from "../util/event-place";
import {listGuestEvents} from "../action/listGuestEvents";
import {store} from "../redux/store";
import {setGuestEvents} from "../redux/actions/setGuestEvents";
import {setArchiveEvents} from "../redux/actions/setArchiveEvents";
import {listArchiveEvents} from "../action/listArchiveEvents";

export const eventsListLoader = async (args) => {
    const eventPlaceId = getCurrentEventPlaceId();
    const eventPlace = getEventPlace(eventPlaceId);
    const dateTo = Math.round(new Date().getTime() / 1000);

    if (eventPlace !== "kg") {
        store.dispatch(setGuestEvents({ guestEvents: await listGuestEvents() }));
    } else {
        store.dispatch(setArchiveEvents({ archiveEvents: await listArchiveEvents({ limit: 9, to: dateTo }) }));
    }

    return { loadedAt: new Date() };
}
