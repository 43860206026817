import * as React from "react";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {blurhash} from "../../Service/Blurhash";

export const BlurhashSliderImage = (props) => {
    const navigate = useNavigate();

    const [blrhsh, setBlrhsh] = useState(``);
    const [hide, setHide] = useState(false);

    useEffect(() => {
        if (!props.imageBlurhash) {
            return
        }
        const im = blurhash.decode(
            props.imageBlurhash.hash,
            Math.round(props.imageBlurhash.width / 40) * 4,
            Math.round(props.imageBlurhash.height / 40) * 4
        );
        blurhash.getImageDataAsImage(im, Math.round(props.imageBlurhash.width / 40) * 4, Math.round(props.imageBlurhash.height / 40) * 4, (e, im) => {
            setBlrhsh(im.src)
        })
    }, [props.imageBlurhash, props.image]);

    return (
        <div className={`blurhash-image-container ${!props.forceBlur && hide ? `blurhash-image-container--hide` : ``}`}>
            <img
                className='slider-image'
                itemID={props.id}
                src={props.image}
                alt={props.id}
                key={props.n}
                height={props.imageBlurhash?.height}
                width={props.imageBlurhash?.width}
                onLoad={() => setHide(true)}
                onClick={() => navigate('/programok/programdetailpage/' + props.id, {
                    state: {
                        programId: props.id,
                        program: props,
                        categoryName: "Következő programok",
                        linkText: "kovetkezoprogramok"
                    }
                })}
                style={{cursor: "pointer", height: props.direction <= 500 ? "100%" : "60%"}}
            />
            <img className="blurhash" src={ blrhsh } />
        </div>
    )
}