import {store} from "../redux/store";
import {setArticles} from "../redux/actions/setArticles";
import {listArticles} from "../action/listArticles";
import {setCurrentEventPlaceId} from "../redux/actions/setCurrentEventPlaceId";
import {getCurrentEventPlaceId} from "../util/event-place";

export const articleFilterLoader = async (args) => {
    store.dispatch(setCurrentEventPlaceId({ eventPlaceId: getCurrentEventPlaceId() }));
    store.dispatch(setArticles(await listArticles({ categoryId: args.params.categoryId })));
    return true;
}