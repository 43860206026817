import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GaleriaKategoriaCard from "../components/cards/GaleriaKategoriaCard"
import tovabb from "../assets/media/icons/tovabb nyil.svg"
import { MDBContainer, MDBCol, MDBRow } from 'mdb-react-ui-kit';
import {useSelector} from "react-redux";


const GaleriaKategoriaPage = () => {

  const navigate = useNavigate();
  const [galleryList, setGalleryList] = useState([]);
  const { eventPlace } = useSelector(state => state);

  useEffect(() => {
    if (eventPlace?.galleries) {
        if (!Array.isArray(eventPlace.galleries)) {
            if (typeof eventPlace.galleries === 'object') {
                setGalleryList(Object.values(eventPlace?.galleries));
            } else {
                setGalleryList(eventPlace?.galleries);
            }
        }
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    
  }, [eventPlace, eventPlace?.galleries]);


  return (
    <>
    <section className="galeria-kategoria-section" id ="galeria-kategoria-section">
      <MDBContainer style={{maxWidth: "1008px", padding: 0}}>
            <div>
              <div className="navigate-div" style={{ marginTop: 0 }}>
                  <button onClick={() => {  navigate('/home')}}>Főoldal</button>
                  <img src={tovabb} alt="tovabb"/>
                  <button >Galéria</button>
              </div>
              <div className="galeria-title">
                <span>Galéria</span>
              </div>
              <MDBRow className="galeria-row">
                {galleryList.map((k,n) => {return (
                  <MDBCol md="3" size="12" className="galeria-col" key={n}>
                    <GaleriaKategoriaCard msg={k}/>
                  </MDBCol>
                )})}
            
              </MDBRow>
            </div>
          
      </MDBContainer>
    </section>
    </>
  );
};

export default GaleriaKategoriaPage;
