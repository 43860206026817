// REACT IMPORTS
import React, { Component, useRef, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
// MDBOOTSTRAP IMPORTS
import { 
  MDBRow, 
  MDBCol, 

  MDBCheckbox
} from 'mdb-react-ui-kit';


// STYLE IMPORTS
import "react-toggle/style.css";
import {sendMessage} from "../../action/sendMessage";


class ProfileForm extends Component {

  constructor(props) {
    super(props);
      this.state = {email: "",
                    firstName: "",
                    lastName: "",
                    targy: "",
                    message: "",
                    info: null
      };
    };

    onCustomer = (e) => {
      e.preventDefault();
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if(this.state.email.match(validRegex)){
        let message = {
          lastName: this.state.lastName,
          firstName: this.state.firstName,
          subject: this.state.targy,
          email: this.state.email,
          message: this.state.message
        }

        sendMessage(message).then(() => {
            this.setState({
                email: "",
                firstName: "",
                lastName: "",
                targy: "",
                message: "",
                info: { message: 'Az üzenet sikeresen el lett küldve!', type: `success` }
            });

            this.props.setChecked(false);

            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }).catch(() => {
            this.setState({ info: { message: 'Hiba történt az üzenet küldésekor!', type: `error` } });
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        })
      }else {
        alert('Az Emailforámatum helytelen!')
      }
      
    }

    changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };
  
  render() {
    
    return (<>
              <form onSubmit={(e) => this.onCustomer(e)} className="profile-form" autoComplete="off">
                { this.state.info !== null ? (
                    <div className={`form-message form-message--${ this.state.info.type } px-1 mb-2`}>{ this.state.info.message }</div>
                ) : null }
                <div className="white-panel">
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='6' className="px-1">
                      <label htmlFor="lastName">Vezetéknév <span>*</span></label>
                      <input
                        id='lastName'
                        name='lastName'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.lastName}
                        onKeyDown={(e) => {
                          if (e.key === 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.firstnameRef.current.focus()
                          }
                        }}
                        required
                      />
                    </MDBCol>
                    <MDBCol md='6' className="px-1">
                      <label htmlFor="firstName">Keresztnév <span>*</span></label>
                      <input
                        id='firstName'
                        name='firstName'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.firstName}
                        ref={this.props.firstnameRef}
                        onKeyDown={(e) => {
                          if (e.key === 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.emailRef.current.focus()
                          }
                        }}
                        required
                      />
                    </MDBCol>
                  </MDBRow>

                
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='6' className="px-1">
                      <label htmlFor="email">E-mail cím <span>*</span></label>
                      <input
                        id='email'
                        name='email'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.email}
                        ref={this.props.emailRef}
                        onKeyDown={(e) => {
                          if (e.key === 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.targyRef.current.focus()
                          }
                        }}
                        required
                      />
                    </MDBCol>
                    <MDBCol md='6' className="px-1">
                      <label htmlFor="targy">Üzenet tárgya <span>*</span></label>
                      <input
                        id='targy'
                        name='targy'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.targy}
                        ref={this.props.targyRef}
                        onKeyDown={(e) => {
                          if (e.key === 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.textRef.current.focus()
                          }
                        }}
                        required
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='12' className="px-1">
                      <label htmlFor="message">Üzenet <span>*</span></label>
                      <textarea 
                        id='message'
                        name='message'
                        type='textarea'
                        onChange={this.changeHandler}
                        value={this.state.message}
                        ref={this.props.textRef}
                        rows="4" 
                        required
                      />
                    </MDBCol>
                  </MDBRow>


                

                  <MDBRow className="m-0">
                    <MDBCol md='12' className="p-0">
                      <MDBRow className="m-0">
                        <MDBCol md='12'  className="d-grid align-items-center justify-content-start p-0 px-1">
                          <div className="checkbox-div d-flex align-items-start pb-4">
                            <MDBCheckbox
                              id='controlledCheckbox_1'
                              label=""
                              checked={this.props.checked}
                              onChange={() => this.props.setChecked(!this.props.checked)}
                              required
                              style={{translate: `0 1.5px`}}
                            /> 
                            <span>Elolvastam és elfogadom az  <a onClick={() => { window.open("https://agora-backend.stage.sites.appentum.pro/files/InfopageDocuments/file/c4f10739-0d2b-4b53-bb3f-1b78417becd8.pdf", '_blank')}} style={{cursor: "pointer"}}>adatvédelmi tájékoztatót</a>.</span>
                            
                          </div>   
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                 
                  </MDBRow>

                  <MDBRow className="m-0">
                    <MDBCol md='12'  className="d-grid align-items-center justify-content-start p-0 px-1" >
                          <div>
                            <button type="submit" className="muster-button kiemelt" >
                            <span>Küldés</span>
                            </button>
                          </div>
                    </MDBCol>
                  </MDBRow>
           
                
                  </div>
              </form>

               
      </>
    );
  }
};

function ProfileFormFunc(props) {
  let [searchParams] = useSearchParams();
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const firstnameRef = useRef();
  const emailRef = useRef();
  const targyRef = useRef();
  const textRef = useRef()
  return <ProfileForm searchParams={searchParams} 
                      navigate={navigate} 
                      firstnameRef={firstnameRef}
                      emailRef={emailRef}
                      targyRef={targyRef}
                      textRef={textRef}
                      checked={checked}
                      setChecked={setChecked}
                      />;
}

export default ProfileFormFunc;
