import React, { useEffect } from "react";
import {useNavigate, useParams} from "react-router-dom";
import HirekCard from "../components/cards/HirekCard"
import tovabb from "../assets/media/icons/tovabb nyil.svg"
import { MDBContainer, MDBCol, MDBRow } from 'mdb-react-ui-kit';
import {useSelector} from "react-redux";

const HirekFilterPage = () => {

  const params = useParams();
  const navigate = useNavigate();
  const { articleCategories, articles } = useSelector(state => state);

  const eventPageName = articleCategories.find(k => k.id === parseInt(params.categoryId))?.name;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);
  
  return (
    <>
   
    <section className="hirekpage-section" id="hirekpage-section">
      <MDBContainer style={{maxWidth: "1008px"}} className='p-0 hirek-div'>
      <div className="navigate-div" style={{ marginTop: 0 }}>
            <button onClick={() => { navigate('/home')}}>Főoldal</button>
            <img src={tovabb} alt="tovabb"/>
            <button onClick={() => {navigate('/hirek/hirek')}}>Hírek</button>
            <img src={tovabb} alt="tovabb"/>
            <button>{eventPageName}</button>
          </div>
        <div className="hirek-title" style={{marginTop: "-72px"}}>
          <span>{eventPageName}</span>
        </div>
        <MDBRow className="m-0 hirek-row">
          {articles.map((k,n) => {return (
            <MDBCol md="4" className="hirek-col px-0" key={n}>
              <HirekCard msg={k}/>
            </MDBCol>
          )})}
          
        </MDBRow>
       
      </MDBContainer>
    </section>
   
    </>
  );
};

export default HirekFilterPage;
