// react imports
import React, {useState, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import moment from 'moment'
// CUSTOM COMPONENTS
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import {RWebShare} from "react-web-share";
import CarouselComponent from '../components/carousel/CarouselComponent.js'
import share from "../assets/media/icons/share.svg"
import {
    MDBContainer
} from 'mdb-react-ui-kit';
import {BlurhashImage} from "../components/blurhash/BlurhashImage";
import tovabbNyil from '../assets/media/icons/tovabb nyil.svg';
import {useSelector} from "react-redux";


const HirekDetailPage = (props) => {

    const navigate = useNavigate();
    // STATES
    const [sliderImages, setSliderImages] = useState([]);
    const [open, setOpen] = useState(false);
    const [direction, setDirection] = useState(window.outerWidth);

    const handleWindowSizeChange = () => {
        setDirection(window.visualViewport.width);
    };

    const videoLink = () => {
        const youtubeUrl = article.youtubeUrl;
        if (youtubeUrl != undefined && youtubeUrl != null) {
            if (youtubeUrl.startsWith("https://www.youtube.com/watch")) {
                let newVideoLink = youtubeUrl.replace("watch?v=", "embed/");
                let andIndex = newVideoLink.indexOf("&");
                if (andIndex != -1) {
                    return (newVideoLink.substring(0, andIndex));
                } else {
                    return (newVideoLink);
                }
            } else {
                if (youtubeUrl.startsWith("https://youtu.be/")) {
                    let newVideoLink = youtubeUrl.replace("youtu.be/", "www.youtube.com/embed/");
                    let andIndex = newVideoLink.indexOf("&");
                    if (andIndex != -1) {
                        return (newVideoLink.substring(0, andIndex));
                    } else {
                        return (newVideoLink);
                    }
                } else {
                    return youtubeUrl;
                }
            }
        }
    }

    const { article } = useSelector(state => state);

    useEffect(() => {
        window.visualViewport.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.visualViewport.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);

    return (<>
            {direction > 810.98 ?
                <section className="hirekdetailpage-section" id="hirekdetailpage-section">
                    {article && (
                        <MDBContainer fluid style={{maxWidth: "1054px"}} className='p-0'>
                            <div className="hirek-background"></div>
                            <div className="hirek-detail">
                                <div className="left-side">
                                    <div className="first-clolumn">
                                        <span
                                            className="datum">{moment.unix(article.datePublished).locale('hu').format('YYYY.MM.DD.')}</span>
                                        {article.articleCategories?.length > 0 && (
                                            <>
                                                <span className="title">Kategória</span>
                                                {article.articleCategories?.map((k, n) => {
                                                    return (
                                                        <span className="description" key={n} onClick={() => {
                                                            navigate('/hirekfilter/' + k.id);
                                                        }}

                                                        >{k.name}</span>
                                                    )
                                                })}

                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="right-side">
                                    <div className="second-column">
                                        <div className="hirek-kep">
                                            {
                                                article.image ? (
                                                    <BlurhashImage image={article.image}
                                                                   blurhash={article.imageBlurhash}/>
                                                ) : null
                                            }
                                        </div>
                                        <div className="hirek-breadcrumb">
                                            <Link to="/">Főoldal</Link>
                                            <img src={tovabbNyil}/>
                                            <Link to="/hirek/hirek">Hírek</Link>
                                        </div>
                                        <span className="title">{article.title}</span>
                                        {article.relatedLinks && article.relatedLinks.length > 0 && (
                                            <div className="pb-4">
                                                {article.relatedLinks.map((k, n) => {
                                                    return (
                                                        <div className="loadmore-div" key={n}>
                                                            <button className="muster-button kiemelt"
                                                                    onClick={() => window.open(k.link, '_blank')}><span
                                                                className="button-text">{k.title}</span></button>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )}
                                        <div className="description"
                                             dangerouslySetInnerHTML={{__html: article.article}}></div>
                                        {article.gallery && article.gallery.length > 0 && (
                                            <MDBContainer fluid className="gallery">
                                                <div className="gallery-title">
                                                    <span>Galéria</span>
                                                </div>
                                                <CarouselComponent gallery={article.gallery} openLightboxlick={() => {
                                                    setSliderImages(article.gallery);
                                                    setOpen(!open)
                                                }}/>
                                            </MDBContainer>
                                        )}
                                        {videoLink() && (
                                            <div className="video">
                                                <iframe src={videoLink() + "?autoplay=0&controls=1&loop=0"}
                                                        allow="autoplay"></iframe>
                                            </div>
                                        )}


                                    </div>
                                    <div className="share-button-div py-4">
                                        <RWebShare
                                            data={{
                                                text: "Az alábbi linket osztották meg Önnel: ",
                                                url: window.location.href,
                                                title: "Megosztás",
                                            }}
                                            onClick={() => console.log("shared successfully!")}
                                        >
                                            <button type="button" className="muster-button kiemelt">
                                                <span>Megosztás </span><img src={share} alt=""/></button>
                                        </RWebShare>
                                    </div>
                                </div>
                            </div>
                        </MDBContainer>
                    )}
                    <Lightbox
                        open={open}
                        close={() => setOpen(false)}
                        slides={sliderImages.map(k => {
                            return ({src: k.imageUrl})
                        })}
                        plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
                    />
                </section> :
                <section className="hirekdetailpage-section-respo" id="hirekdetailpage-section">
                    {article && (
                        <MDBContainer fluid style={{maxWidth: "1259px"}} className='p-0'>
                            <div className="boritokep-div">
                                <img src={article.image} alt="hirek-borito"/>
                            </div>
                            <div className="hirek-detail">

                                <span
                                    className="datum">{moment.unix(article.datePublished).locale('hu').format('YYYY.MM.DD.')}</span>
                                {article.articleCategories?.length > 0 && (
                                    <>
                                        <span className="title-url">Kategória</span>
                                        {article.articleCategories?.map((k, n) => {
                                            return (
                                                <span className="description-url" key={n} onClick={() => {
                                                    navigate('/hirekfilter/' + k.id);
                                                }}

                                                >{k.name}</span>
                                            )
                                        })}

                                    </>
                                )}
                                <span className="title">{article.title}</span>
                                {article.relatedLinks && article.relatedLinks.length > 0 && (
                                    <div className="pb-4">
                                        {article.relatedLinks.map((k, n) => {
                                            return (
                                                <div className="loadmore-div" key={n}>
                                                    <button className="muster-button kiemelt"
                                                            onClick={() => window.open(k.link, '_blank')}><span
                                                        className="button-text">{k.title}</span></button>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                                <div className="description" dangerouslySetInnerHTML={{__html: article.article}}></div>
                                <div className="share-button-div py-4">
                                    <RWebShare
                                        data={{
                                            text: "Az alábbi linket osztották meg Önnel: ",
                                            url: window.location.href,
                                            title: "Megosztás",
                                        }}
                                        onClick={() => console.log("shared successfully!")}
                                    >
                                        <button type="button" className="muster-button kiemelt">
                                            <span>Megosztás </span><img src={share} alt=""/></button>
                                    </RWebShare>
                                </div>
                                {article.gallery && article.gallery.length > 0 && (
                                    <MDBContainer fluid className="gallery">
                                        <div className="gallery-title">
                                            <span>Galéria</span>
                                        </div>
                                        <CarouselComponent gallery={article.gallery} openLightboxlick={() => {
                                            setSliderImages(article.gallery);
                                            setOpen(!open)
                                        }}/>
                                    </MDBContainer>
                                )}
                                {videoLink() && (
                                    <div className="video">
                                        <iframe src={videoLink() + "?autoplay=0&controls=1&loop=0"}
                                                height={window.innerWidth / 1920 > 1 ? 456 : window.innerWidth > 810 ? (window.innerWidth / 1920) * 456 : (window.innerWidth - 50) * 9 / 16}
                                                width={window.innerWidth / 1920 > 1 ?
                                                    811 : window.innerWidth > 810 ? (window.innerWidth / 1920) * 811 : window.innerWidth - 50}
                                                allow="autoplay" title="myFrame"></iframe>
                                    </div>
                                )}
                            </div>
                        </MDBContainer>
                    )}
                    <Lightbox
                        open={open}
                        close={() => setOpen(false)}
                        slides={sliderImages.map(k => {
                            return ({src: k.imageUrl})
                        })}
                        plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
                    />
                </section>}
        </>

    );
};

export default HirekDetailPage;
