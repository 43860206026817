import React, {useEffect} from "react";
import {Outlet} from "react-router-dom";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import HirlevelModal from "../modals/HirlevelModal";
import TbkartyaModal from "../modals/TbkartyaModal";
import {useSelector} from "react-redux";

function Layout() {

    const { eventPlace, modals } = useSelector(state => state)

    useEffect(() => {
        document.body.style.setProperty('--box-bg', eventPlace.color);
        document.body.style.setProperty('--box-font', eventPlace.id === 6 ? "'IM FELL English'" : 'Urbanist');
    }, [
        eventPlace
    ])

    return (
        <>
            <div className="layout">

                <Header/>
                <main>
                    <Outlet/>
                </main>
                <Footer/>

                {modals.newsletter && (
                    <HirlevelModal visible={modals.newsletter} />
                )}
                {modals.card && (
                    <TbkartyaModal visible={modals.card} />
                )}
            </div>
        </>

    );
}

export default Layout;
