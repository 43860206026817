// react imports
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// CUSTOM COMPONENTS
import HirekCard from "../components/cards/HirekCard"
import tovabb from "../assets/media/icons/tovabb nyil.svg"
import { MDBContainer, MDBCol, MDBRow } from 'mdb-react-ui-kit';
import {listArticles} from "../action/listArticles";
import {useDispatch, useSelector} from "react-redux";
import {pushArticles} from "../redux/actions/pushArticles";


const HirekListPage = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // STATES

  const { articles, areMoreArticles, eventPlace } = useSelector(state => state);

  const loadMoreHirek = async () => {
    dispatch(pushArticles(await listArticles({ limit: 9, from: articles[articles.length -1].datePublished - 1 })));
  };

  useEffect(() => {
    setTimeout(() => window.scrollTo({
      top: 0,
      behavior: "smooth"
    }), 100);
  }, []);


  return (
    <>
   
    <section className="hirekpage-section" id="hirekpage-section">
      <MDBContainer style={{maxWidth: "1008px"}} className='p-0 hirek-div'>
      <div className="navigate-div" style={{ marginTop: 0 }}>
            <button onClick={() => { navigate('/home')}}>Főoldal</button>
            <img src={tovabb} alt="tovabb"/>
            <button onClick={() => navigate('/hirek/hirek')}>Hírek</button>
          </div>
        <div className="hirek-title">
          <span>Hírek</span>
        </div>
        <MDBRow className="hirek-row">
          {articles.map((k,n) => {return (
            <MDBCol xl="4" lg="6" md="6" size="12" className="hirek-col px-0" key={n}>
              <HirekCard msg={k}/>
            </MDBCol>
          )})}
          
        </MDBRow>
        {areMoreArticles && (
        <div className="loadmore-div">
              <button type="button" className="muster-button kiemelt-next" onClick={loadMoreHirek}><span>Továbbiak </span></button>
            </div>)}
      </MDBContainer>
    </section>
   
    </>
  );
};

export default HirekListPage;
