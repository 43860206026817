import {createReducer} from "@reduxjs/toolkit";
import {setInfopages} from "./actions/setInfopages";
import {setArticleCategories} from "./actions/setArticleCategories";
import {setEventTypes} from "./actions/setEventTypes";
import {setGuestEvents} from "./actions/setGuestEvents";
import {setEventPlaces} from "./actions/setEventPlaces";
import {setArticles} from "./actions/setArticles";
import {setHomepage} from "./actions/setHomepage";
import {pushArticles} from "./actions/pushArticles";
import {setArticle} from "./actions/setArticle";
import {setRecurringEvents} from "./actions/setRecurringEvents";
import {setCurrentEventPlaceId} from "./actions/setCurrentEventPlaceId";
import {openModal} from "./actions/openModal";
import {closeModal} from "./actions/closeModal";
import {setSearchFunction} from "./actions/setSearchFunction";
import {setSearchText} from "./actions/setSearchText";
import Cookies from 'universal-cookie';
import {setProgramCategoryId} from "./actions/setProgramCategoryId";
import {setInfoPageSource} from "./actions/setInfoPageSource";
import {setArchiveEvents} from "./actions/setArchiveEvents";
import {pushArchiveEvents} from "./actions/pushArchiveEvents";
import {setEvent} from "./actions/setEvent";
import {setParentInfopage} from "./actions/setParentInfopage";
import {setGalleryPage} from "./actions/setGalleryPage";
import {pushGalleryPage} from "./actions/pushGalleryPage";
import {setInfopage} from "./actions/setInfopage";

const cookies = new Cookies();

const initialState = {
    article: null,
    articles: [],
    areMoreArticles: false,
    articleCategories: [],
    eventTypes: [],
    guestEvents: [],
    infopages: [],
    currentEventPlaceId: 1,
    eventPlaces: [],
    eventPlace: {
        phone: '',
    },
    homePage: null,
    modals: {
        password: false,
        newsletter: false,
        card: false
    },
    isSearchFunction: false,
    searchText: ``,
    programCategoryId: cookies.get('programCategoryId')?.programCategoryId,
    infoPageSource: ``,
    archiveEvents: [],
    areMoreArchiveEvents: false,
    event: null,
    parentInfopage: null,
    infopage: null
}

export const agoraReducer = createReducer( initialState, builder => {
    builder.addCase(setArticles, (state, action) => {
        state.articles = action.payload.articles;
        state.areMoreArticles = action.payload.hasMoreItems === true;
    })

    builder.addCase(setArticle, (state, action) => {
        state.article = action.payload;
    })

    builder.addCase(pushArticles, (state, action) => {
        state.articles = [ ...state.articles, ...action.payload.articles ];
        state.areMoreArticles = action.payload.hasMoreItems === true;
    })

    builder.addCase(setArticleCategories, (state, action) => {
        state.articleCategories = action.payload.articleCategories;
    })

    builder.addCase(setEventTypes, (state, action) => {
        state.eventTypes = action.payload.eventTypes;
    })

    builder.addCase(setEventPlaces, (state, action) => {
        state.eventPlaces = action.payload.eventPlaces;
        state.eventPlace = action.payload.eventPlaces.find(eventPlace => eventPlace.id === state.currentEventPlaceId);
    })

    builder.addCase(setHomepage, (state, action) => {
        state.homePage = action.payload.homePage;
    })

    builder.addCase(setGuestEvents, (state, action) => {
        state.guestEvents = action.payload.guestEvents;
    })

    builder.addCase(setInfopages, (state, action) => {
        state.infopages = action.payload.infopages;
    })

    builder.addCase(setRecurringEvents, (state, action) => {
        state.recurringEvents = action.payload.recurringEvents;
    })

    builder.addCase(setCurrentEventPlaceId, (state, action) => {
        state.currentEventPlaceId = action.payload.eventPlaceId;
        state.eventPlace = state.eventPlaces.find(eventPlace => eventPlace.id === action.payload.eventPlaceId);
    })

    builder.addCase(openModal, (state, action) => {
        state.modals[action.payload] = true;
    })

    builder.addCase(closeModal, (state, action) => {
        state.modals[action.payload] = false;
    })

    builder.addCase(setSearchFunction, (state, action) => {
        state.isSearchFunction = !state.isSearchFunction;
    })

    builder.addCase(setSearchText, (state, action) => {
        state.searchText = action.payload;
    })

    builder.addCase(setProgramCategoryId, (state, action) => {
        state.programCategoryId = action.payload;
    })

    builder.addCase(setInfoPageSource, (state, action) => {
        state.infoPageSource = action.payload;
    })

    builder.addCase(setArchiveEvents, (state, action) => {
        state.archiveEvents = action.payload.archiveEvents.events;
        state.areMoreArchiveEvents = action.payload.archiveEvents.hasMoreItems === true;
    })

    builder.addCase(pushArchiveEvents, (state, action) => {
        state.archiveEvents = [...state.archiveEvents, ...action.payload.archiveEvents.events];
        state.areMoreArchiveEvents = action.payload.archiveEvents.hasMoreItems === true;
    })

    builder.addCase(setEvent, (state, action) => {
        state.event = action.payload;
    })

    builder.addCase(setParentInfopage, (state, action) => {
        state.parentInfopage = action.payload;
    })

    builder.addCase(setGalleryPage, (state, action) => {
        state.galleryImages = action.payload.images;
        state.areMoreGalleryImages = action.payload.hasMoreItems === true;
    })

    builder.addCase(pushGalleryPage, (state, action) => {
        state.galleryImages = [ ...state.galleryImages, ...action.payload.images ];
        state.areMoreGalleryImages = action.payload.hasMoreItems === true;
    })

    builder.addCase(setInfopage, (state, action) => {
        state.infopage = action.payload;
    })
} )