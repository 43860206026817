import React, { Component, useRef, useState } from "react";
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import SingleImageUploader from '../image-uploader/SingleImageUploader';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { MDBRow, MDBCol, MDBDatepicker, MDBCheckbox } from 'mdb-react-ui-kit';
import "react-toggle/style.css";
import {openModal} from "../../redux/actions/openModal";
import {store} from "../../redux/store";
import InterAxios from "../../Service/InterAxios";
import Cookies from 'universal-cookie';
import {useSelector} from "react-redux";

const cookies = new Cookies();
const getAuthToken = () => cookies.get('authtoken')?.token;
const interAxios = new InterAxios('App', getAuthToken);
const customWeekdaysNarrow = ['V', 'H', 'K', 'Sz', 'Cs', 'P', 'Sz'];
const customMonthsFull = [ 'Január', 'Február', 'Március', 'Április', 'Május', 'Június', 'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December', ]

class TbKartyaForm extends Component {
 
  constructor(props) {

    super(props);
      this.state = {};
    };

    onCustomer = (e) => {
      e.preventDefault();
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if(this.state.email.match(validRegex)){
        let self = this;
        const formData = new FormData
        let customer = {title: this.state.title,
          name: this.state.name,
          birthname: this.state.birthname,
          mothers_name: this.state.mothers_name,
          gender: this.state.gender,
          birth_date:this.state.birth_date,
          birth_place:this.state.birth_place,
          zip_code:this.state.zip_code,
          city:this.state.city,
          address:this.state.address,
          phone:this.state.phonenumber,
          email:this.state.email,
          banktransfer_date:this.state.utalas,}
        formData.append("data", JSON.stringify(customer));
        formData.append("image", this.state.profileImageUrl, this.state.profileImageUrl.name);
        //
  
       interAxios.post('/tatabanya-card/request', formData, { headers: {'Content-Type': 'multipart/form-data'}},
        function(response) {
          store.dispatch(openModal('card'))
          self.setState({
            title: "",
            name: "",
            birthname: "",
            mothers_name: "",
            gender: "",
            birth_date: "",
            birth_date_2: "",
            birth_place: "",
            zip_code: "",
            city: "",
            address: "",
            phonenumber: "+36",
            email: "",
            utalas: "",
            utalas_2: ""})
          self.setState({profileImageUrl: undefined})
          self.props.setChecked(false)
          self.props.setChecked2(false)
        });

        
      }else {
        alert('Az Emailforámatum helytelen!')
      }
      
    }

    changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };
  
  render() {
    return (<>
              <form onSubmit={(e) => this.onCustomer(e)} className="profile-form profile-form--tbkartya" autoComplete="off">
                <div className="white-panel">
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='6' className="ps-0">
                      <label htmlFor="title">Titulus</label>
                      <input
                        id='title'
                        name='title'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.title}
                        onKeyDown={(e) => {
                          if (e.key === 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.nameRef.current.focus()
                          }
                        }}
                        
                      />
                    </MDBCol>
                    <MDBCol md='6' className="pe-0">
                      <label htmlFor="name">Név <span>*</span></label>
                      <input
                        id='name'
                        name='name'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.name}
                        ref={this.props.nameRef}
                        onKeyDown={(e) => {
                          if (e.key === 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.szuletesinevRef.current.focus()
                          }
                        }}
                        required
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='6' className="ps-0">
                      <label htmlFor="birthname">Születéskori név <span>*</span></label>
                      <input
                        id='birthname'
                        name='birthname'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.birthname}
                        ref={this.props.szuletesinevRef}
                        onKeyDown={(e) => {
                          if (e.key === 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.anyjaneveRef.current.focus()
                          }
                        }}
                        required
                      />
                    </MDBCol>
                    <MDBCol md='6' className="pe-0">
                      <label htmlFor="mothers_name">Anyja leánykori neve <span>*</span></label>
                      <input
                        id='mothers_name'
                        name='mothers_name'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.mothers_name}
                        ref={this.props.anyjaneveRef}
                        onKeyDown={(e) => {
                          if (e.key === 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.genderRef.current.focus()
                          }
                        }}
                        required
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='6' className="ps-0">
                      <label htmlFor="gender">Neme <span>*</span></label>
                      <input
                        id='gender'
                        name='gender'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.gender}
                        ref={this.props.genderRef}
                        required
                       
                      />
                    </MDBCol>
                    <MDBCol md='6' className="pe-0 birth-field">
                      <label htmlFor="birth_date">Születési dátum <span>*</span></label>
                      <MDBDatepicker  inline
                        customIcon='fa-regular fa-calendar-alt fa-lg'
                        format='yyyy-mm-dd'
                        max={new Date()}
                        min={new Date(1900, 1, 1)}
                        monthsFull={customMonthsFull}
                        weekdaysNarrow={customWeekdaysNarrow}
                        value={this.state.birth_date_2}
                        required
                        onKeyDown={(e) => {
                          if (e.key === 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.birthPlaceRef.current.focus()
                          }
                        }}
                       
                        onChange={val => {
                          var newDate = new Date( val);
                          this.setState({birth_date: Math.round(newDate.getTime() / 1000), birth_date_2: val});
                        }}/>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='6' className="ps-0">
                      <label htmlFor="birth_place">Születési hely <span>*</span></label>
                      <input
                        id='birth_place'
                        name='birth_place'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.birth_place}
                        ref={this.props.birthPlaceRef}
                        onKeyDown={(e) => {
                          if (e.key === 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.zipCodeRef.current.focus()
                          }
                        }}
                        required
                      />
                    </MDBCol>
                    <MDBCol md='6' className="pe-0">
                      <label htmlFor="zip_code">Irányítószám <span>*</span></label>
                      <input
                        id='zip_code'
                        name='zip_code'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.zip_code}
                        ref={this.props.zipCodeRef}
                        onKeyDown={(e) => {
                          if (e.key === 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.cityRef.current.focus()
                          }
                        }}
                        required
                      />
                    </MDBCol>
                  </MDBRow>

                
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='6' className="ps-0">
                      <label htmlFor="city">Város <span>*</span></label>
                      <input
                        id='city'
                        name='city'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.city}
                        ref={this.props.cityRef}
                        onKeyDown={(e) => {
                          if (e.key === 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.addressRef.current.focus()
                          }
                        }}
                        required
                      />
                    </MDBCol>
                    <MDBCol md='6' className="pe-0">
                      <label htmlFor="address">Utca, házszám <span>*</span></label>
                      <input
                        id='address'
                        name='address'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.address}
                        ref={this.props.addressRef}
                        required
                    
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='6' className="ps-0">
                      <label htmlFor="phonenumber">Telefonszám</label>
                      
                      <PhoneInput
                        country="hu"
                        preferredCountries={['hu']}
                        masks={{hu: '.. ... ....'}}
                        id='phonenumber'
                        name='phonenumber'
                        type='text'
                        value={this.state.phonenumber}
                        onChange={val => {
                          this.setState({phonenumber: val});
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.emailRef.current.focus()
                          }
                        }}
                      />
                    </MDBCol>
                    <MDBCol md='6' className="pe-0">
                      <label htmlFor="email">E-mail cím <span>*</span></label>
                      <input
                        id='email'
                        name='email'
                        type='email'
                        onChange={this.changeHandler}
                        value={this.state.email}
                        ref={this.props.emailRef}
                        required
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='6' className="ps-0">
                      <label htmlFor="utalas">Banki átutalás ideje <span>*</span></label>
                      <MDBDatepicker  inline
                        customIcon='fa-regular fa-calendar-alt fa-lg'
                        format='yyyy-mm-dd'
                        max={new Date()}
                        min={new Date(2024, 1, 1)}
                        value={this.state.utalas_2}
                        monthsFull={customMonthsFull}
                        weekdaysNarrow={customWeekdaysNarrow}
                        required
                        
                        onChange={val => {
                          var newDate = new Date( val);
                          this.setState({utalas: Math.round(newDate.getTime() / 1000), utalas_2: val});
                        }}/>
                    </MDBCol>
                    <MDBCol md='6' className="pe-0">
                      <label htmlFor="utca">Kép feltöltés <span>*</span></label>
                      
                        <SingleImageUploader
                          uploadBtnMessage={"Kép feltöltése"}
                          imageUrl= {this.state.profileImageUrl}
                          setNewImageUrl = {(profileImageUrl) => this.setState({profileImageUrl: profileImageUrl})}
                      />
                    </MDBCol>
                  </MDBRow>
                

                  <MDBRow className="pt-5 m-0">
                    <MDBCol md='12' className="p-0">
                      <MDBRow className=" m-0">
                        <MDBCol md='12'  className="d-grid align-items-center justify-content-start p-0">
                          <div className="checkbox-div d-flex align-items-start pb-4">
                            <MDBCheckbox
                              id='controlledCheckbox_1'
                              label=""
                              checked={this.props.checked}
                              onChange={() => this.props.setChecked(!this.props.checked)}
                              style={{translate: `0 1.5px`}}
                              required
                            /> 
                            <span>Elolvastam és megértettem az <Link to={'/infosfooldal/' + this.props.infopages.filter(k => k.title === "Adatkezelési tájékoztatók")[0]?.id} target="_blank" rel="noopener noreferrer">ÁSZF</Link>-ben foglaltakat.</span>
                            
                          </div>   
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                 
                  </MDBRow>
                  <MDBRow className=" m-0">
                    <MDBCol md='12' className="p-0">
                      <MDBRow className="pb-4 m-0">
                        <MDBCol md='12'  className="d-grid align-items-center justify-content-start p-0">
                          <div className="checkbox-div d-flex align-items-start pb-4">
                            <MDBCheckbox
                              id='controlledCheckbox_2'
                              label=""
                              checked={this.props.checked2}
                              onChange={() => this.props.setChecked2(!this.props.checked2)}
                              style={{translate: `0 1.5px`}}
                              required
                            /> 
                            <span>Kijelentem, hogy a Tatabánya Kártya kedvezményrendszer működtetésével kapcsolatos adatkezelésre vonatkozó <a href="https://agora-backend.stage.sites.appentum.pro/files/InfopageDocuments/file/88228098-05df-4ce3-a47f-7e7b24e090b7.pdf" target="_blank">adatkezelési tájékoztató</a>t megismertem.</span>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                 
                  </MDBRow>
                  <MDBRow className="m-0">
                    <MDBCol md='12'  className="d-grid align-items-center justify-content-start px-0" >
                          <div>
                            <button type="submit" className="muster-button kiemelt" >
                            <span>Küldés</span>
                            </button>
                          </div>
                    </MDBCol>
                  </MDBRow>
           
                
                  </div>
              </form>

               
      </>
    );
  }
};

function TbKartyaFormFunc(props) {
  let [searchParams] = useSearchParams();
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const navigate = useNavigate();
  const utalasRef = useRef();
  const emailRef = useRef();
  const phonenumberRef = useRef();
  const addressRef = useRef();
  const cityRef = useRef();
  const zipCodeRef = useRef();
  const birthPlaceRef = useRef();
  const birthDateRef = useRef();
  const genderRef = useRef();
  const anyjaneveRef = useRef();
  const szuletesinevRef = useRef();
  const nameRef = useRef();
  const {infopages} = useSelector(state => state);
  return <TbKartyaForm searchParams={searchParams} 
                      navigate={navigate} 
                      utalasRef={utalasRef}
                      emailRef={emailRef}
                      phonenumberRef={phonenumberRef}
                      addressRef={addressRef}
                      cityRef={cityRef}
                      zipCodeRef={zipCodeRef}
                      birthPlaceRef={birthPlaceRef}
                      birthDateRef={birthDateRef}
                      genderRef={genderRef}
                      anyjaneveRef={anyjaneveRef}
                      szuletesinevRef={szuletesinevRef}
                      nameRef={nameRef}
                      checked={checked}
                      setChecked={setChecked}
                      checked2={checked2}
                      setChecked2={setChecked2}
                       infopages={infopages}
                      />;
}

export default TbKartyaFormFunc;
