export const MenuList = [
    
        {
            id: 1,
            title: "Programok",
            activeItem: 1,
            link: "/programok",
            dropddown_list: [   {
                                    title: "Következő programok",
                                    link: "/programok/kovetkezoprogramok",
                                    linkEnd: "/kovetkezoprogramok",
                                    dropddown_list: []
                                },
                                {
                                    title: "Ingyenes programok",
                                    link: "/programok/ingyenesprogramok",
                                    linkEnd: "/ingyenesprogramok",
                                    dropddown_list: []
                                },
                                {
                                    title: "Gyermekprogramok",
                                    link: "/programok/gyermekprogramok",
                                    linkEnd: "/gyermekprogramok",
                                    dropddown_list: []
                                },
                                {
                                    title: "Agora mozi",
                                    link: "/programok/agoramozi",
                                    linkEnd: "/agoramozi",
                                    dropddown_list: []
                                },
                                {
                                    title: "Állandó programok",
                                    link: "/programok/allandoprogramok",
                                    linkEnd: "/allandoprogramok",
                                    dropddown_list: []
                                },
                                {
                                    title: "Archívum",
                                    link: "/programok/archivum",
                                    linkEnd: "/archivum",
                                    dropddown_list: []
                                }
                            ]
        },
        {
            id: 2,
            title: "Hírek",
            activeItem: 2,
            link: "/hirek",
            dropddown_list: [ 
                            ]
        },
        {
            id: 6,
            title: "Kortárs Galéria",
            eventplace: "kg",
            kiemelt: "#F0B323",
            font: 'IM FELL English',
            link: "/home",
            activeItem: 4,
            dropddown_list: []
            
        },
        {
            id: 5,
            title: "Művelődési házak",
            activeItem: 5,
            link: "/muvhazak",
            dropddown_list: [   
                                {
                                    title: "A Vértes Agorája",
                                    eventplace: "ava",
                                    kiemelt: "#F0B323",
                                    font: 'Urbanist',
                                    link: "/home",
                                    id: 1
                                    
                                },
                                {
                                    title: "József Attila Művelődési Ház",
                                    eventplace: "jamh",
                                    kiemelt: "#54A8DE",
                                    font: 'Urbanist',
                                    link: "/home",
                                    id: 2
                                    
                                },
                                {
                                    title: "Kertvárosi Bányász Művelődési Otthon",
                                    eventplace: "kbmo",
                                    kiemelt: "#FA8934",
                                    font: 'Urbanist',
                                    link: "/home",
                                    id: 3
                                   
                                },
                                {
                                    title: "Puskin Művelődési Ház",
                                    eventplace: "pmh",
                                    kiemelt: "#AFCA21",
                                    font: 'Urbanist',
                                    link: "/home",
                                    id: 4
                                    
                                },
                                {
                                    title: "Széchenyi István Művelődési Ház",
                                    eventplace: "szimh",
                                    kiemelt: "#9A96F7",
                                    font: 'Urbanist',
                                    link: "/home",
                                    id: 5
                                }
                            ]
        },
        {
            id: 7,
            title: "Média",
            activeItem: 6,
            link: "/media",
            dropddown_list:  [   {
                                    title: "YouTube",
                                    link: "https://www.youtube.com/channel/UC7fJ9vLgnIfiPZFpeLrO6oA",
                                    linkEnd: "/youtube",
                                    dropddown_list: []
                                },
                                {
                                    title: "Facebook",
                                    link: "https://www.facebook.com/avertesagoraja.hu",
                                    linkEnd: "/facebook",
                                    dropddown_list: []
                                },
                                {
                                    title: "Instagram",
                                    link: "https://www.instagram.com/avertesagoraja.tatabanya?igsh=MWxyNGszcnQ4Zzd2OQ==",
                                    linkEnd: "/instagram",
                                    dropddown_list: []
                                }
                            ]
        }
   
]
